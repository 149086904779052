<template>
  <div class="cotainer" v-loading="loading">
    <el-breadcrumb class="p_tb_10" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item,index) in breadcrumb" :to="item.path" :key="index">{{
        item.name
      }}</el-breadcrumb-item>
        <el-breadcrumb-item v-show="breadcrumb.length>0">内容详情</el-breadcrumb-item>
      <!-- <el-breadcrumb-item :to="{ path: '/party/exemplaryVanguard'}">模范先锋</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/party/exemplaryVanguard/schoolList'}">先进党组织</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item> -->
    </el-breadcrumb>
    <div class="m_tb_20 box">
      <div class="title">{{contents.title}}</div>
      <div class="contents">
<div class="pic" v-show="contents.url">
      <img :src="contents.url" width="100%" v-if="checkFile(contents.url)=='img'"/>
      <video v-else :src="contents.url" controls autoplay width="100%"></video>
    </div>
        <div class="content">{{contents.content}}</div>

      </div>
    </div>

  </div>
</template>

<script>
  import {
    getDetail
  } from '@/api/party/index'
  export default {
    name: 'politicalLifeDetail',
    data: () => {
      return {
contents:'',
        loading:false,
      }
    },
    created() {
      this.loading=true;
      this.getContent();
      let forms=parseInt(this.$route.query.from);
      switch(forms){
           case 1:
           this.breadcrumb=[{
             name:'模范先锋',path:'/party/exemplaryVanguard?id='+this.$route.query.id
           }]
           break;
           case 2:
           this.breadcrumb=[{
             name:'模范先锋',path:'/party/exemplaryVanguard?id='+this.$route.query.id
           },{
             name:'先进党组织',path:'/party/exemplaryVanguard/schoolList?id='+this.$route.query.id+'&cid='+this.$route.query.listId
           }]
           break;
           case 3:
           this.breadcrumb=[{
             name:'政治生活',path:'/party/politicalLife?id='+this.$route.query.id
           }]
           break;

      }
    },
    components: {

    },
    methods: {
//获取内容详情
     async getContent(){
       const {data}= await getDetail({id:this.$route.query.cid});
       this.contents=data;
       this.loading=false;
      },
      checkFile(fileUrl) {
          // 取出文件后缀名
          if(fileUrl){
            let suffixArr = fileUrl.split('.');
            let fileSuffix = suffixArr[suffixArr.length - 1];

            const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
            if(imgList.indexOf(fileSuffix) > -1){
              return 'img'
            }else{
                console.log(fileSuffix);
            }

            const videoList = ['mp4', 'rmvb', 'avi', 'flv', 'm2v', 'mkv', 'wmv', 'mp3', 'wav'];
            if(videoList.indexOf(fileSuffix) > -1){
                 return 'video'
            }
          }

      }
    }
  }
</script>
<style lang="scss" scoped>
  @font-face {
    font-family: PingFang-SC;
    src: url("../../../assets/fonts/苹方黑体-中粗-简.ttf");
  }

  .cotainer {

    .box {
      .title {
        font-family: PingFang-SC;
        font-size: 40px;
        text-align: center;
      }

      .pic {
        // height: 320px;
        width: 100%;
        margin-top: 20px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;

      }

      .contents{
        padding-bottom: 20px;
        .content{
          font-size: 15px;
          line-height: 18px;
          font-size: PingFang SC;
          opacity: 0.8;
          word-wrap:break-word;
           white-space:pre-wrap;
          text-align: center;
        }

        .media{
          text-align: center;
        }
      }
    }



  }
</style>
